import IcoBlank from '@/assets/img/icons/ico_share_white@2x.png';
import Balloon from '@/assets/img/mission/ico_balloon@2x.png';
import Bn from '@/assets/img/mission/sustainable_bn@2x.png';
import Img1 from '@/assets/img/mission/sustainable_img1@2x.jpg';
import Img2 from '@/assets/img/mission/sustainable_img2@2x.jpg';
import card10 from '@/assets/img/mission/tab_card_img10@2x.png';
import card11 from '@/assets/img/mission/tab_card_img11@2x.png';
import card12 from '@/assets/img/mission/tab_card_img12@2x.png';
import card13 from '@/assets/img/mission/tab_card_img13@2x.png';
import card14 from '@/assets/img/mission/tab_card_img14@2x.png';
import card15 from '@/assets/img/mission/tab_card_img15@2x.png';
import card16 from '@/assets/img/mission/tab_card_img16@2x.png';
import card17 from '@/assets/img/mission/tab_card_img17@2x.png';
import card1 from '@/assets/img/mission/tab_card_img1@2x.png';
import card2 from '@/assets/img/mission/tab_card_img2@2x.png';
import card3 from '@/assets/img/mission/tab_card_img3@2x.png';
import card4 from '@/assets/img/mission/tab_card_img4@2x.png';
import card5 from '@/assets/img/mission/tab_card_img5@2x.png';
import card6 from '@/assets/img/mission/tab_card_img6@2x.png';
import card7 from '@/assets/img/mission/tab_card_img7@2x.png';
import card8 from '@/assets/img/mission/tab_card_img8@2x.png';
import card9 from '@/assets/img/mission/tab_card_img9@2x.png';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

const cardData = [
  {
    data: [
      {
        id: 0,
        img: card1,
        tit: `모든 곳에서 모든 형태의 빈곤 종식`,
        dec: `빈곤은 영양, 보건, 식수, 교육, 보호 등 어린이의 기본적인 권리를 누리지 못하게 하며 더 나은 미래를 준비하기 어렵게 만듭니다. 전 세계적인 노력이 없다면 어린이 빈곤은 사회적 불평등을 야기하고 가장 취약한 어린이가 필요한 서비스를 이용하기 어렵게 될 것입니다. 전 세계 어린이 5명 중 1명이 극심한 빈곤에 시달리고 있습니다. 유니세프는 모든 어린이의 기본적인 욕구를 충족할 수 있도록 사회 서비스 개선에 투자합니다.`,
      },
      {
        id: 1,
        img: card2,
        tit: `기아 종식, 식량 안보, 영양 개선, 지속가능한 농업 증진`,
        dec: `영양은 어린이 생존과 발달에 토대가 됩니다. 골고루 영양을 섭취할 수 있는 어린이는 성장하고 배우고 지역 사회에 참여하며 질병, 재난 및 기타 긴급 상황에 대처할 수 있습니다. 전 세계 5세 미만 어린이 사망의 주요 원인은 영양 결핍입니다. 유니세프는 발육부진, 저체중, 체력저하, 비만 등 모든 형태의 영양실조를 예방하는 데 중점을 두고 영양 상태 개선을 위해 노력합니다. 유니세프는 모든 어린이와 여성이 잠재력을 발휘하는 데 필요한 안전하고 영양가 있고 저렴한 지속가능 시간을 갖출 수 있도록 지원합니다.
        `,
      },
      {
        id: 2,
        img: card3,
        tit: `모든 이의 건강한 삶 보장과 삶의 질 증진`,
        dec: `그 어느때보다 많은 어린이가 건강하게 살고 있지만, 2018년에만 어린이 620만 명이 예방가능한 질병으로 세상을 떠났습니다. 유니세프는 전 세계 의료 시스템을 강화하기 위해 노력합니다. 각국이 예방가능한 질병으로 인한 어린이 사망을 줄이고 질병을 치료할 수 있도록 지원합니다. 정신 건강과 발달 지연 및 장애 어린이를 도와줍니다. 산모가 안전한 환경에서 출산할 수 있도록 돕고 모든 어린이의 건강한 발달을 위해 지역사회의 1차 의료 기관의 업무를 확대할 수 있도록 지원합니다.`,
      },
    ],
  },
  {
    data: [
      {
        id: 3,
        img: card4,
        tit: `포괄적이고 공평한 양질의 교육을 보장하고 모두를 위한 평생학습의 기회 증진`,
        dec: `오늘날 더 많은 어린이가 교육기관에 등록해 공부하고 있고 남녀 차이도 줄었지만, 많은 경우 학교 교육이 배움으로 이어지진 않습니다. 교육에 대한 접근성 향상과 학습의 질을 향상하고자 하는 노력이 필요합니다. 모든 어린이가 학교에서 공부하는 것은 질병과 싸우는 일만큼이나 중요합니다. 유니세프는 형평성과 포용성을 기반으로, 유아기부터 청년기에 이르기까지 모든 어린이에게 양질의 학습 기회와 기술 개발 프로그램을 제공하고 있습니다.`,
      },
      {
        id: 4,
        img: card5,
        tit: `성평등 달성 및 모든 여성과 여자 어린이의 역량 강화`,
        dec: `가정을 책임지기 위해 학교를 그만 두거나 이른 나이에 결혼을 하고 성폭력을 경험하는 등 성 기반 유해한 규범은 다양합니다. 성별 불균형은 출산할 때부터 시작되어 자라날수록 고착화됩니다. 현재 전 세계 약 6억 5천만 명이 조혼으로 가정을 이뤘고, 2억 명 이상이 할례를 당했습니다. 양성 평등은 인권이며 빈곤을 줄이고 개발을 위한 전제 조건입니다. 유니세프는 남녀 어린이 모두가 동일한 권리, 기회 및 보호를 누릴 수 있도록 전 세계에서 일합니다. 양상 평등을 전제로 유니세프 프로그램을 운영하며, 보호자에게 긍정적인 양육법도 교육합니다. 또한 각국 정부가 정확한 정보를 토대로 성 평등을 이룰 수 있도록 데이터를 분석합니다.`,
      },
      {
        id: 5,
        img: card6,
        tit: `모든 사람에게 물과 위생시설 이용과 지속가능한 유지 관리 보장`,
        dec: `오염된 물과 열악한 위생 시설은 5세 미만 어린이 사망의 주요 원인입니다. 안전한 식수와 위생 시설이 없다면 예방 가능한 질병에 쉽게 노출되고 영양실조, 발육 부진 등 심각한 건강 문제가 생깁니다. 또한 교육 및 양성평등 다른 주요 목표에도 영향을 미칩니다. 유니세프는 모든 어린이가 건강하고 안전한 환경에서 배우고 자랄 수 있도록 가정, 학교, 보건소 등에 깨끗한 물과 기본적인 위생시설을 보급합니다.`,
      },
    ],
  },
  {
    data: [
      {
        id: 6,
        img: card7,
        tit: `모든 사람이 적절한 가격의 신뢰성 높고 지속가능한 현대적 에너지원에 접근하도록 보장`,
        dec: `매년 5세 미만 어린이 50만 명이 대기오염 관련 질병으로 사망합니다. 뇌와 폐에도 끔찍한 영향을 미칩니다. 저렴하고 깨끗한 에너지에 대한 접근은 어린이 발달과 복지에 매우 중요합니다. 지속가능에너지는 전력망이 없는 지역에 전력을 공급해 조명으로 연결하거나 학교 급식을 만드는 데 도움을 줍니다. 태양열 라디오로 교육을 지원하기도 합니다. 유니세프는 지속가능에너지를 어린이의 교육과 보건 사업을 개선하는 해결책으로 활용합니다.`,
      },
      {
        id: 7,
        img: card8,
        tit: `모두를 위한 일관성 있고 포괄적이며 지속가능한 경제 성장 및 생산적인 완전고용과 양질의 일자리 증진`,
        dec: `전 세계 청년 실업률은 13%로 성인보다 3배 더 높습니다. 교육 및 기술 훈련에 대한 투자가 없다면 빠르게 늘고 있는 청년 인구는 일할 준비를 하지 못할 것입니다. 또한 전 세계 어린이 10명 중 1명이 일을 하며 절반 이상이 위험한 노동을 하고 있습니다. 어린이 노동은 어린이 빈곤의 원인이자 결과이며 사회적 불평등과 차별을 야기합니다. 유니세프는 전 세계적으로 어린이 노동을 예방하고 대응하며, 공급망과 비즈니스 관행이 어린이에게 미치는 영향을 해결하고 어린이에게 재활 서비스를 제공하고 있습니다. 또한 유엔 협력기관과 함께 2030년까지 모든 청년이 학교에 다니거나 훈련을 받거나 취업을 한 상태에 이르도록 하는 파트너십 제너레이션 언리미티드(Generation Unlimited)를 통해 청년 기술 및 고용을 증진합니다.`,
      },
      {
        id: 8,
        img: card9,
        tit: `회복력 높은 사회기반시설 구축, 포괄적이고 지속가능한 산업화 증진 및 혁신 촉진`,
        dec: `18세에서 24세 사이 3분의 1에 해당하는 40억 명이 인터넷을 이용하지 못합니다. 청년의 삶의 질을 향상시킬 수 있는 디지털 기술과 세계에서 뒤쳐지고 있습니다. 유니세프는 개발 및 인도주의 측면에서 혁신을 주요 쟁점으로 다루고 있습니다. 유니세프는 파트너와 협력해 백신을 차갑게 유지하는 시스템을 개발하고 어린이 영양 실조를 치료하고 농촌 지역에 안전한 물을 공급하는 데 기술 혁신을 이뤘습니다.`,
      },
    ],
  },
  {
    data: [
      {
        id: 9,
        img: card10,
        tit: `국내 및 국가 간 불평등 감소`,
        dec: `빈곤 퇴치를 위한 노력이 국가 간 및 국내에서 고르게 이뤄지지 않았습니다. 빈곤은 어린이 돌봄과 필수 서비스 이용에 걸림돌입니다. 성별, 장애, 언어, 민족에 따른 차별은 불평등을 부채질합니다. 유니세프는 가난과 차별로 인한 피해를 줄이는 사회 보호 프로그램과 정책에 투자합니다. 모든 어린이가 양육 수당, 학교 급식, 기술 개발 및 현금 이전 등 사회 보호 프로그램을 공평하게 지원받을 수 있도록 합니다.`,
      },
      {
        id: 10,
        img: card11,
        tit: `포용적이고 안전하며, 회복력 있는 지속 가능 도시와 거주지 조성`,
        dec: `세계 어린이 절반이 도시 지역에 삽니다. 다음 세기 중반에 이르면 70%에 이를 것으로 예상됩니다. 1996년부터 유니세프는 아동친화도시이니셔티브를 추진했습니다. 유니세프는 어린이가 깨끗한 환경에서 안전하게 놀고, 배우며 성장할 수 있도록 정부를 지원합니다. 또한 어린이 관련 정책과 예산에 어린이의 의견이 반영될 수 있도록 합니다.`,
      },
      {
        id: 11,
        img: card12,
        tit: `지속가능한 소비와 생산`,
        dec: `독성 폐기물을 만들고 천연자원을 줄이는 생산이 계속되고 있습니다. 어린이는 환경 파괴에 대한 책임이 가장 적지만 가장 큰 부담을 지게 됩니다. 하지만 때로는 재활용 및 플라스틱 사용 감소 등 환경 보호를 위한 행동 변하는 종종 어린이가 시작하기도 합니다. 유니세프는 어린이가 목소리를 높일 수 있도록 지원합니다.`,
      },
    ],
  },
  {
    data: [
      {
        id: 12,
        img: card13,
        tit: `기후변화와 그 영향에 대처하기 위한 긴급 대응`,
        dec: `기후변화는 어린이의 생존, 성장과 발달에 영향을 미칩니다. 기후변화로 인한 질병 부담의 90%는 5세 미만 어린이가 부담하게 됩니다. 전 세계 어린이는 기후변화와 환경 오염으로 인해 훨씬 더 위험하고 불확실한 세상에서 성장해야 합니다.
        기후변화에 대한 효과적인 대응은 어린이를 보호하고 권리를 실현하는 데 필수적입니다. 유니세프는 어린이가 안전하고 깨끗한 환경에서 살 수 있도록 협력하며, 어린이 스스로가 기후변화 대응 전략과 계획의 중심이 되어 변화의 주체가 될 수 있도록 지원합니다.`,
      },
      {
        id: 13,
        img: card14,
        tit: `지속가능발전을 위한 대양, 바다, 해양자원의 보존과 지속가능한 사용`,
        dec: `어린이 5억 3천만 명이 홍수 발생 지역에 살고 있으며 어린이 4명 중 1명이 2040년까지 물로 인한 스트레스가 극심한 지역에서 살 것입니다. 전 세계적으로 기후변화에 따른 피해는 물의 변화를 통해 체감합니다. 유니세프는 해수면 상승, 가뭄, 수질 오염, 폭우, 홍수 등에 영향을 받는 개발도상국 어린이가 깨끗하고 안전한 물을 이용할 수 있도록 지원합니다.`,
      },
      {
        id: 14,
        img: card15,
        tit: `육상 생태계의 보호, 복원 및 지속가능한 이용 증진, 삼림의 지속가능한 관리, 사막화 방지, 토지의 황폐화 방지와 복구, 생물다양성 손실 중지`,
        dec: `살림 및 생태계 손실과 토지 황폐화는 기후변화와 관련 있으며 어린이 성장과 발달에 큰 위협이 됩니다. 유니세프는 어린이가 더불어 살아갈 지구의 문제에 대해 고민하고 문제 해결을 위한 어린이의 목소리를 높일 수 있도록 어린이 참여를 증진합니다. 또한 유엔과 협력해 어린이가 야생 동물 불법 거래에 참여하지 않도록 보호합니다.`,
      },
    ],
  },
  {
    data: [
      {
        id: 15,
        img: card16,
        tit: `지속가능발전을 위한 평화롭고 포용적인 사회 촉구, 모두를 위한 사법제도, 모든 단계에서 효율성 높고 책임성 있고 포용적인 제도 구축`,
        dec: `모든 어린이는 폭력과 학대, 방치로부터 보호해야 합니다. 하지만 전 세계 어린이 수백만 명이 가정, 학교, 지역사회 및 온라인 등에서 폭력에 시달리고 있습니다. 폭력은 신체적, 정신적, 성적 등 다양한 성태로 발생합니다. 폭력을 당하거나 목격한 어린이의 피해는 평생 지속될 수 있습니다. 유니세프는 각국 정부가 보건, 사회, 사법 등에서 보다 강력한 사회 보호 시스템을 구축하고 폭력, 학대, 착취 관련 기존 규범을 정비할 수 있도록 협력하여 모든 종류의 폭력을 종식하는 데 힘씁니다.`,
      },
      {
        id: 16,
        img: card17,
        tit: `이행수단 강화와 지속가능발전을 위한 글로벌 파트너십 활성화`,
        dec: `모든 어린이를 위한 일에 성과를 내기 위해서는 파트너십이 중요합니다. 모든 사람은 지속가능발전목표를 달성하는 데 역할과 책임이 있습니다. 유니세프는 모든 어린이가 행복한 세상을 위해 전 세계, 지역, 국가 및 지역사회 등 모든 수준에 걸쳐 광범위한 파트너와 협력합니다.`,
      },
    ],
  },
];

const Cardflex = styled.ul`
  display: flex;
  align-items: stretch !important;
  flex-wrap: wrap;
  position: relative;
  & > li {
    width: 33.33%;
    margin-bottom: 32px;
  }

  .btn-item {
    display: block;

    &._active {
      transform: translate(0, -16px);
    }
    ${Image} {
      display: block;
      width: 100%;
    }
  }
  .tab-contents {
    padding: 74px 40px 64px;
    border-radius: 19px;
    box-shadow: 7px 0 15px 0 rgba(0, 0, 0, 0.04),
      0 2px 24px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #b2def1;
    background-color: #ffffff;
    width: calc(300% + 64px);
    margin-top: 35px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 62px;
      height: 45px;
      background: url(${Balloon}) center no-repeat;
      background-size: cover;
      position: absolute;
      top: -40px;
      left: 45px;
    }

    &.t-1 {
      transform: translate(-50%, 0);
      left: 50%;

      &::before {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    &.t-2 {
      transform: translate(-100%, 0);
      left: 100%;
      &::before {
        left: auto;
        right: 45px;
      }
    }
    .dec {
      margin-top: 32px;
    }
  }
  ${breakpoint(1160)} {
    .tab-contents {
      &::before {
        left: 3.88vw;
      }

      &.t-2 {
        &::before {
          left: auto;
          right: 3.88vw;
        }
      }
      .dec {
        margin-top: 32px;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    & > li {
      /* margin-bottom: 16px; */
    }
    .tab-contents {
      margin-top: 26px;
      padding: 28px 20px 24px;
      width: calc(300% + 48px);

      &::before {
        left: 11.25vw;
      }

      &.t-2 {
        &::before {
          left: auto;
          right: 11.25vw;
        }
      }
      .dec {
        margin-top: 32px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .tab-contents {
      &::before {
        left: 8.59vw;
      }

      &.t-2 {
        &::before {
          left: auto;
          right: 8.59vw;
        }
      }
    }
  }
`;
const TabCard = styled.div`
  .group-flex {
    flex-wrap: wrap;
    align-items: stretch;
    & > li {
      width: 50%;
    }
  }
  ${breakpoint(`tablet`)} {
    .group-flex {
      & > li {
        width: 100%;
      }
    }
  }
`;
const BnLink = styled(LinkSafe)`
  display: block;
  padding: 55px 20px;
  border: solid 2px #1cabe2;
  background-color: #ffffff;
  margin-top: 64px;

  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;

    dt {
      width: 37%;
      padding-left: 60px;
      ${Tit} {
        margin: 2px 0;
        display: block;

        &.more {
          display: inline-block;
        }
      }
    }
    dd {
      width: 64%;
    }
  }
  ${breakpoint(`tablet`)} {
    padding: 32px 20px;
    margin-top: 56px;

    dl {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      dt,
      dd {
        width: 100%;
      }
      dt {
        margin-top: 32px;
        padding-left: 0;
      }
    }
  }
`;
const AgreementContainer = styled.div`
  dl {
    dt,
    dt {
      display: block;
      width: 100%;
    }
    dt {
      padding: 20px 32px;
      background-color: #e2f0f6;
      line-height: 1.6;
    }
    dd {
      padding: 32px;
      padding-bottom: 64px;
    }
  }

  .card-flex {
    margin-top: 32px;
    align-items: stretch;

    .link-item {
      height: 100%;
      position: relative;
      display: block;
      padding-top: 66.8%;
      background-color: #f26a21;
    }

    & > li {
      width: 50%;

      &:last-child {
        .link-item {
          background-color: #80bd41;
        }
      }
    }

    .img-wrap {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .dec-wrap {
      padding: 16px 31px 20px 16px;
      .dec {
        color: #fff;
        font-size: 14px;
        margin-top: 8px;
      }
    }

    .link-blank {
      color: #fff;
      text-decoration: none;

      &::after {
        background-image: url(${IcoBlank});
      }
    }
  }

  ${breakpoint(`tablet`)} {
    dl {
      dt {
        padding: 15px 20px;
      }
      dd {
        padding: 14px 0 48px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .card-flex {
      margin-top: 24px;
      flex-wrap: wrap;

      & > li {
        width: 100%;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
const SectionTop = styled(Section)`
  ${TabCard} {
    margin-top: 112px;
  }
  ${breakpoint(`tablet`)} {
  }
`;

const SustainableDevelopmentGoals: React.FC<PageProps> = ({ location }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabMenu = (id: number) => {
    setActiveTab(id);
  };
  return (
    <LayoutWithTitle
      location={location}
      title="지속가능발전목표"
      description="Sustainable Development Goals(SDGs)"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모두가 행복한 지구촌을 위한, <br />
                  지속가능발전목표
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                지속가능발전목표는 2015년 제70차 UN총회에서 2030년까지 전 세계가
                함께 빈곤 퇴치, 양질의 교육 환경, 환경보호, 불평등 해소 등을
                위해 결의한 의제로 17개 주목표와 169개 세부 목표로 구성돼
                있습니다. 모든 어린이가 행복한 세상은 모두가 살기 좋은
                세상입니다. 아동권리가 실현되어야 지속가능발전목표를 달성할 수
                있습니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle
              className="youtube-container"
              videoId="KfooEfLOpeI"
            />
          </PageComponent>

          <TabCard>
            <ul className="group-flex flex">
              {cardData.map((row, index) => (
                <li key={row.id}>
                  <Cardflex className="flex">
                    {row.data.map((row2, index2) => (
                      <li key={row2.id}>
                        <figure
                          className={
                            activeTab === index * 3 + index2
                              ? `btn-item _active`
                              : `btn-item`
                          }
                          active={activeTab === index * 3 + index2}
                          onClick={() => handleTabMenu(row2.id)}
                        >
                          <Image pcSrc={row2.img} mobileSrc={row2.img} />
                        </figure>
                        {activeTab === index * 3 + index2 && (
                          <div className={`tab-contents t-${index2}`}>
                            <Tit size="s4" color="sky">
                              {row2.tit}
                            </Tit>
                            <p className="dec">{row2.dec}</p>
                          </div>
                        )}
                      </li>
                    ))}
                  </Cardflex>
                </li>
              ))}
            </ul>
          </TabCard>

          <BnLink
            target="_blank"
            to={`${process.env.BACKEND_URL}/unicef/api/ebook/view?path=/ebook/crc-publications/728/`}
          >
            <dl>
              <dt>
                <Tit size="s4" color="sky">
                  아동권리협약과 지속가능발전목표 맵핑
                </Tit>
                <Tit size="s4" className="more">
                  자세히 보기
                </Tit>
              </dt>
              <dd>
                <Image pcSrc={Bn} mobileSrc={Bn} />
              </dd>
            </dl>
          </BnLink>
        </Container>
      </SectionTop>

      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                어린이를 위한 지속가능발전목표 달성, <br />
                어떻게 할 수 있나요?
              </Tit>
            </h2>
          </SectionHeader>
          <AgreementContainer>
            <dl>
              <dt>
                <Tit size="s4-1" color="sky">
                  1 . 인식하기
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  올바른 정보와 방법으로 어린이는 지속가능발전 실행 및
                  모니터링에서 중요한 역할을 할 수 있습니다. 지역사회가
                  지속가능발전목표달성을 위해 행동할 수 있도록 하는 힘이
                  어린이에게 있습니다. 유니세프는 어린이가 지속가능발전목표를
                  이해하고 지역사회의 문제점을 인식할 수 있도록 교육합니다.
                </p>

                <ul className="card-flex flex">
                  <li>
                    <LinkSafe
                      to="/what-we-do/advocacy-for-children/child-rights-education"
                      className="link-item"
                    >
                      <div className="img-wrap">
                        <Image pcSrc={Img1} mobileSrc={Img1} />
                      </div>
                      <div className="dec-wrap">
                        <Tit size="s4" color="white" className="link-blank">
                          World’s Largest Lesson
                        </Tit>
                        <p className="dec">
                          유니세프는 학생들이 학교에서 수업을 통해
                          지속가능발전목표를 이해하고 지구촌 문제 해결을 위해
                          고민하며 행동할 수 있도록 구성된 수업 자료를
                          배포합니다.
                        </p>
                      </div>
                    </LinkSafe>
                  </li>
                  <li>
                    <div className="link-item">
                      <div className="img-wrap">
                        <Image pcSrc={Img2} mobileSrc={Img2} />
                      </div>
                      <div className="dec-wrap">
                        <Tit size="s4" color="white" className="blank-item">
                          Youth Activate Talk
                        </Tit>
                        <p className="dec">
                          세상을 바꿀 어린이가 지구촌 문제에 대해 목소리를 높일
                          수 있도록 지원하는 프로그램입니다.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1" color="sky">
                  2. 행동하기
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  보건에서 영양, 교육, 보호에 이르기까지 유니세프의 활동은
                  지속가능발전목표와 연결되어 있습니다. 유니세프는 정부가
                  프로그램과 정책을 계획하고 예산을 책정하고 시행할 때 어린이를
                  최우선으로 고려할 수 있도록 지원합니다. 주로 기술과 지식을
                  제공하는 형태로 협력합니다. 지속가능발전목표 달성을 위한
                  자료를 수집하고 평가하는 데 협력하며 어린이가 참여할 수 있도록
                  지원합니다. 또한 매년 정부가 지속가능발전목표를 추진할 때
                  고려해야할 주요 요구 사항을 개발합니다.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1" color="sky">
                  3. 책임지기
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  유니세프는 1) 어린이 관련 자료를 수집하고 분석 및 공유,
                  진행상황 모니터링을 하며 2) 어린이 관점의 지속가능발전목표에
                  대해 의사결정권자에게 전달하여 책임을 높이도록 하는 데
                  협력합니다. 예를 들어, 유니세프인도사무소는 ‘어린이 관점의
                  지속가능발전목표 기초 자료 보고서’를 통해 중앙 정부가
                  지속가능발전목표 이행과 모니터링할 때 어린이가 소외되지 않도록
                  지원했습니다. 유니세프가나사무소에서는 커뮤니티 참여를 위한
                  SMS 기반 도구인 U-report를 통해 어린이의 지속가능발전목표 인식
                  조사를 실시하고 정부가 지속가능발전목표 이행하는데 어린이가
                  참여할 수 있도록 지원했습니다.
                </p>
              </dd>
            </dl>
          </AgreementContainer>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};
export default SustainableDevelopmentGoals;
